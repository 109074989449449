
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

$color: #fbfbfe;

.link-container {
    background: $color;
    border-radius: 0.5em;
    border: 1px dashed #d8d8d8;
    height: 100%;
    transition: background-color 0.3s ease;

    &:hover {
        background: darken($color, 2%);
    }
}
