
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import 'cropperjs/dist/cropper.css';

.cropper-img-container::v-deep {
    max-height: 100%;
    overflow: hidden;

    > [role='progressbar'] {
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
    }

    img {
        display: block;
        width: 100%;
        max-width: 100%;
        max-height: 50vh;
        //margin: 0 auto;
    }

    .cropper-container.cropper-bg {
        background-repeat: repeat;
    }

    .cropper-crop-box {
        .cropper-dashed {
            border-style: solid;
            border-color: $doger-blue !important;
            opacity: 1;
        }

        .cropper-point {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: $white;
            border: 1px solid $doger-blue;
            opacity: 1;

            &.point-n {
                top: -6px;
            }

            &.point-e {
                right: -6px;
            }

            &.point-s {
                bottom: -6px;
            }

            &.point-w {
                left: -6px;
            }

            &.point-ne {
                right: -5px;
                top: -5px;
            }

            &.point-nw {
                left: -5px;
                top: -5px;
            }

            &.point-sw {
                bottom: -5px;
                left: -5px;
            }

            &.point-se {
                bottom: -5px;
                right: -5px;
            }
        }
    }
}

.cropper-actions::v-deep {
    .ratio-toggle.primary--text {
        pointer-events: none;
    }
}
