
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.v-input-phone {
    &.v-text-field--filled .v-label {
        top: 13px;
        left: 0px;
        right: auto;
        position: absolute;

        &.v-label--active {
            top: 18px;
        }
    }

    &.v-text-field--single-line {
        .vue-tel-input {
            align-items: center;
            .vti__input {
                margin-bottom: 0;
            }
        }
    }

    .vue-tel-input {
        border: none;
        width: calc(100% + 11px);
        margin-left: -11px;
        align-items: end;

        &:focus-within {
            border: none;
            box-shadow: none;
        }

        .vti__input {
            margin-bottom: 2px;
        }

        .vti__dropdown {
            &.open,
            &:hover {
                background-color: transparent;
            }
        }

        .vti__dropdown-list {
            padding: 0;

            .vti__search_box {
                width: calc(100% - 20px);
                margin: 10px;
                padding: 3px 5px;
            }

            .vti__dropdown-item {
                padding: 4px 7px;

                &:focus,
                &:focus-visible {
                    outline: none;
                }

                .vti__flag-wrapper {
                    width: 35px;

                    .vti__flag {
                        margin-right: 10px;
                    }
                }

                strong {
                    font-weight: 500;
                }
            }
        }
    }

    .v-input__control [role='progressbar'] {
        position: relative;
        top: -9px;
        margin-bottom: -2px;
    }
}
