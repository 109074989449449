
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.logo-rounded {
    max-height: 200px;
    max-width: 100%;
    width: auto;
    height: 100%;
    border-radius: 10px;
}
