
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.assignment-comments-drawer {
    max-width: 600px !important;

    .comments-header {
        border-bottom: 1px solid $tertiary-color;
    }
}
