
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            
@import '~vuetify/src/styles/settings/_variables';
.amp-module-page {
    position: relative;

    .amp-module-action-button-hoisted {
        position: absolute;
        top: -70px;
        right: 12px;

        &.v-size--default {
            min-width: 140px;
        }

        &-secondary {
            position: absolute;
            top: 14px;
            right: 12px;
        }
    }

    .amp-module-element-hoisted {
        position: absolute;
        top: -5em;
        right: 12px;
    }

    &.wide {
        .amp-module-action-button-hoisted {
            right: 0;
            top: -82px;
        }

        &-secondary {
            position: absolute;
            top: 14px;
            right: 0;
        }

        .amp-module-element-hoisted {
            right: 0;
        }
    }
}

.pointing-validation,
.elevated-input {
    [role='alert']:first-of-type:after {
        content: '';
        position: absolute;
        border: 8px solid transparent;
        border-bottom-color: currentColor;
        left: 16px;
        top: -16px;
        opacity: 0.12;
    }
}

.elevated-input-first {
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.elevated-input {
    height: 100%;
    box-shadow: 0px -3px 0px 0px white, 0px 3px 0px 0px white,
        0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 3px 5px 0px rgb(0 0 0 / 12%);
}

.elevated-input-last {
    height: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 5px 0px rgb(0 0 0 / 12%);
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .save-edit-button {
        position: fixed;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
