
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-module-editor::v-deep {
    background-color: $white;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 3;

    .save-button {
        background-color: $blueish;
        border-radius: 0px;

        .v-btn__content {
            text-transform: uppercase !important;
        }
    }
}
