
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-module-header::v-deep {
    background-color: $blueish;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
    z-index: 4;
    position: relative;

    .company-name {
        min-height: 40px;
    }

    .author-request-actions {
        position: relative;
        top: calc(-50% + 10px);

        &.mobile {
            position: absolute;
            top: 6px;
            right: 0;
        }
    }
}

@media only screen and (min-width: #{map-get($grid-breakpoints, 'md') + 175}) and (max-width: #{map-get($grid-breakpoints, 'lg') - 0.02}), only screen and (min-width: #{map-get($grid-breakpoints, 'lg') + 175}) and (max-width: #{map-get($grid-breakpoints, 'xl') - 0.02}) {
    .amp-module-header::v-deep .author-request-actions {
        position: absolute;
        z-index: 3;
        top: 1.75em;
        right: 1em;
    }
}
