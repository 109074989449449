
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.media-uploader--wrapper {
    width: 100%;
    height: 100%;

    .file-invalid {
        .filepond--file-wrapper:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 5px solid $error;
            border-radius: 0.45em;
            z-index: 3;
            pointer-events: none;
        }
    }

    &.croppable:not(.uploading) .filepond--image-preview-wrapper {
        cursor: pointer;
    }
    // leave the space for uploading indicator
    &.uploading .filepond--file-action-button.filepond--action-edit-item {
        visibility: hidden;
    }
}
