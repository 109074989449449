
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import '~vuetify/src/styles/settings/_variables';
.amp-link-button.v-size--default {
    min-width: 140px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .amp-links {
        position: fixed;
        z-index: 10;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 24px;
    }

    .amp-link-button {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
    }
}
