<template>
    <section class="references-section">
        <amp-row-first />

        <amp-row class="amp-loading-indicator">
            <template #input>
                <v-sheet
                    absolute
                    light
                    opacity="1"
                    class="py-sm-4 py-md-8 py-lg-12"
                >
                    <div class="text-center mx-auto py-lg-12">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            class="d-inline-block"
                        />
                        <p class="d-inline-block pl-2">
                            {{ label }}
                        </p>
                    </div>
                </v-sheet>
            </template>
        </amp-row>

        <amp-row-last />
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import {
    AmpRowFirst,
    AmpRow,
    AmpRowLast
} from '@/components/AmpModule/AmpPage';

const AmpLoadingCardProps = Vue.extend({
    props: {
        label: {
            type: String,
            default() {
                return 'Generating...';
            }
        }
    }
});

@Component({
    components: {
        AmpRowFirst,
        AmpRow,
        AmpRowLast
    }
})
export default class AmpLoadingCard extends AmpLoadingCardProps {}
</script>
<style type="sass">
.amp-loading-indicator p {
    text-transform: capitalize;
}
</style>
