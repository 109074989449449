
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.date-fields::v-deep {
    background-color: $main-background;
    padding-right: 1em;
    border-radius: 4px;

    .field__publish-date {
        width: 8em;
    }

    .field__publish-time {
        width: 6em;
    }

    .v-input__slot {
        background-color: $main-background !important;
    }
}
