
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.v-select::v-deep {
    max-width: min-content;
    padding-top: 0;
    margin-top: 0;

    > .v-input__control {
        > .v-input__slot {
            &:before {
                display: none;
            }

            &:after {
                display: none;
            }
        }
    }

    .v-input__append-inner svg {
        height: 16px;
        width: 16px;
    }
}
