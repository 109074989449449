
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-publish {
    .v-label:not(.v-label--is-disabled) {
        color: $secondary-color;
    }
}
