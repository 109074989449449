
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.disabled {
    opacity: 0.6;
    pointer-events: none;
}

a.hoverable {
    color: $secondary-color;
    transition: all 0.15s ease-out;
    text-decoration: none;
    display: contents;

    &:hover {
        color: $primary-color;

        .v-icon {
            color: $primary-color;
        }
    }
}
