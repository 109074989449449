
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.always-on::v-deep {
    pointer-events: none;

    .v-input--selection-controls__input {
        opacity: 0.4;
    }
}
