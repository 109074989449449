
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.references-section::v-deep {
    margin-bottom: 1em;

    &.opened {
        .icon,
        .label {
            color: $primary-color !important;
        }
    }

    .elevated-input-first {
        padding: 0 !important;
        border-top: 6px solid $faded-blue !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
