
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.google-maps-container {
    min-height: 250px;
    clip-path: circle(125px);
    transition: clip-path 1s;

    &:hover {
        clip-path: circle(100%);
    }

    &:empty {
        display: none;
    }
}
