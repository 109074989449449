
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.status-chip::v-deep {
    &:not(.custom-colored) {
        &.draft,
        &.in_progress,
        &.awaiting_preview_audio,
        &.assigned,
        &.recurring,
        &.flagged {
            color: $dark-orange;
            background-color: $light-orange;

            .v-chip__content {
                color: $dark-orange;
            }
        }
        &.rejected,
        &.paused {
            background-color: $concrete-solid;
            color: $boulder;

            .v-chip__content {
                color: $boulder;
            }
        }
        &.live,
        &.published,
        &.premium,
        &.financial,
        &.msn,
        &.resolved,
        &.published_module,
        &.authored,
        &.fulfilled {
            color: $turquoise;
            background-color: $light-turquoise;

            .v-chip__content {
                color: $turquoise;
            }
        }
        &.in_queue,
        &.awaiting_distribution,
        &.unapproved,
        &.in_review,
        &.awaiting_client,
        &.pending_distribution,
        &.awaiting_author,
        &.generating,
        &.partially_published,
        &.pending,
        &.active {
            color: $grey-blue;
            background-color: $faded-blue;

            .v-chip__content {
                color: $grey-blue;
            }
        }
        &.premium {
            color: $turquoise;
            background-color: $concrete-solid;

            .v-chip__content {
                color: $turquoise;

                .v-icon {
                    color: $turquoise;
                }
            }
        }
        &.msn {
            color: $msn-blue;
            background-color: $concrete-solid;
        }
        &.financial {
            color: map-get($purple, 'base');
            background-color: $concrete-solid;

            .v-chip__content {
                color: map-get($purple, 'base');

                .v-icon {
                    color: map-get($purple, 'lighten-1');
                }
            }
        }
        &.selective_distribution,
        &.recurring_campaign {
            color: $secondary-color-light;
            background-color: $concrete-solid;

            .v-chip__content {
                color: $secondary-color-light;

                .v-icon {
                    color: $secondary-color-light;
                }
            }
        }

        &.first_recurring_request {
            color: $light-black;
            background-color: $toxic-yellow;

            .v-chip__content {
                color: $light-black;
            }
        }

        &.action_needed,
        &.cancelled {
            color: $white;
            background-color: desaturate($error, 25%);

            .v-chip__content {
                color: $white;
            }
        }
        &.msn {
            color: $electric-blue;
            background-color: $concrete-solid;

            .v-chip__content {
                color: $electric-blue;

                .v-icon {
                    color: $electric-blue;
                }
            }
        }
        &.dfy {
            color: $white;
            background-color: $dark-orange;

            .v-chip__content {
                color: $white;
            }
        }
        &.diy {
            color: $white;
            background-color: $turquoise;

            .v-chip__content {
                color: $white;
            }
        }
        &.ampcast_distribution {
            background-color: transparent;

            &:hover {
                background-color: #59748a;
            }
        }
    }
}
