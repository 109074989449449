
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.modal-wrapper {
    &.v-card > .v-card__text {
        color: $secondary-color;

        .section-text {
            font-size: 1.3em;
        }
    }
}
