
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import 'filepond/dist/filepond.css';
@import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
@import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';

.filepond--wrapper {
    width: 100%;
    height: 100%;
    border-radius: 7px;
    display: flex;

    .filepond--root {
        font-family: 'DM Sans', sans-serif;
        width: 100%;
        align-self: center;
        margin-bottom: 0;

        &:hover:not([data-disabled]) {
            color: $primary-color;

            .filepond--drop-label {
                color: $primary-color;
            }
        }

        .filepond--drop-label {
            color: $secondary-color;

            label {
                font-size: 0.875em;
                cursor: pointer;

                small {
                    font-size: 0.8em;
                }
            }
        }
    }
}
